body {
  background-color: rgb(240, 242, 245);
}

.form-item-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.form-item-input-element {
  width: 300px;
}

.section-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

.form-item-privacy-control-element {
  width: 150px;
}