.main {
  display: flex;
  justify-content: center;
}

.login-form {
  width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  margin-top: calc(5vh + 100px);
}

.login-form .logo {
  font-size: 40px;
  margin-bottom: 40px;
  font-weight: bold;
}

.otp-container {
  display: flex;
  margin-top: 10px;
  width: 100%;
}

.otp-container-button {
  margin-left: 6px;
}

.submit-button {
  width: 100%;
  margin-top: 30px;
}
