body {
  background-color: rgb(240, 242, 245);
}

.alumni-list {
  display: flex;
  flex-wrap: wrap;
}

.alumni-card {
  width: 300px;
  margin: 20px;
  cursor: pointer;
}

.tag {
  color: #828282;
}

.tag::after {
  content: "";
  border: 0.5px solid #c3c3c3;
  margin: 0 8px 0 6px;
}

.form-item {
  margin-bottom: 10px;
}

.form-select {
  width: 200px;
}

.form-function-line {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.pagination {
  margin: 15px 20px 40px;
}